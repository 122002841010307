import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getTxnDetails } from "../../services/user";
import moment from "moment";
import "./details.css";

function UserTxnDetails() {
  const { id, email } = useParams();

  const [data, setData] = useState({});

  const {
    nium_transaction_id,
    synapse_transaction_id,
    account_number,
    beneficiary_name,
    ifsc_code,
    upi_id,
    ach_initiated_time,
    ach_completed_time,
    clearing_account_transfer_time,
    nium_booked_amount,
    nium_transaction_initiate_time,
    nium_transaction_completed_time,
    cash_in_status,
    cash_out_status,
    failure_reason,
    payment_reason_code,
    payment_reason,
    transaction_tracking_id,
  } = data;

  useEffect(() => {
    if (email && id) {
      getTxnDetails(email, id)
        .then((response) => {
          setData(response);
        })
        .catch((error) => {});
    }
  }, [email, id]);

  return (
    <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "25px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Link to={-1}>
          <ArrowBackIcon
            fontSize="large"
            color="secondary"
            sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
          />
        </Link>
        <Typography
          variant="h5"
          component="h5"
          fontWeight={"bold"}
          color={"#3d3d3d"}
        >
          Transaction ID ({id})
        </Typography>
      </div>
      <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
        <div className="row ">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  Tracking Id
                </label>
                <input
                  value={transaction_tracking_id}
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  Synapes Txn ID
                </label>
                <input
                  value={synapse_transaction_id}
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  ACH initiated Date
                </label>
                <input
                  value={
                    ach_initiated_time
                      ? moment(ach_initiated_time).format("L")
                      : ""
                  }
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  ACH completed Date
                </label>
                <input
                  value={
                    ach_completed_time
                      ? moment(ach_completed_time).format("L")
                      : ""
                  }
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  Clearing transferred Date
                </label>
                <input
                  value={
                    clearing_account_transfer_time
                      ? moment(clearing_account_transfer_time).format("L")
                      : ""
                  }
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  NIUM Booked INR
                </label>
                <input
                  value={nium_booked_amount}
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  NIUM trxn Inititated Date
                </label>
                <input
                  value={
                    nium_transaction_initiate_time
                      ? moment(nium_transaction_initiate_time).format("L")
                      : ""
                  }
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  NIUM Txn Paid
                </label>
                <input
                  value={
                    nium_transaction_completed_time
                      ? moment(nium_transaction_completed_time).format("L")
                      : ""
                  }
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  Cash In Status
                </label>
                <input
                  value={cash_in_status}
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  Cash out Status
                </label>
                <input
                  value={cash_out_status}
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  Name of Beneficiary
                </label>
                <input
                  value={beneficiary_name}
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  NIUM Txn ID
                </label>
                <input
                  value={nium_transaction_id}
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
            {upi_id && (
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    UPI ID
                  </label>
                  <input
                    value={upi_id}
                    type="text"
                    className={`form-control `}
                    disabled
                  />
                </div>
              </div>
            )}
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  Payment Reason Code
                </label>
                <input
                  value={payment_reason_code}
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label fw-bold text-secondary user-select-none">
                  Payment Reason
                </label>
                <input
                  value={payment_reason}
                  type="text"
                  className={`form-control `}
                  disabled
                />
              </div>
            </div>
            {failure_reason && (
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    Failure Reason
                  </label>
                  <input
                    value={failure_reason}
                    type="text"
                    className={`form-control `}
                    disabled
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            {account_number && (
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    A/c No. (last 4)
                  </label>
                  <input
                    value={account_number}
                    type="text"
                    className={`form-control `}
                    disabled
                  />
                </div>
              </div>
            )}
            {ifsc_code && (
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label fw-bold text-secondary user-select-none">
                    IFSC Code
                  </label>
                  <input
                    value={ifsc_code}
                    type="text"
                    className={`form-control `}
                    disabled
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default UserTxnDetails;
