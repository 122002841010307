import { getUserToken } from "../../libs/user";
import {
  getPureJSON,
  handleAPIError,
  startLoader,
  stopLoader,
} from "../../libs/utils";
import appConfig from "../../config/app.config";
import axios from "axios";

const getActivityList = () => {
  startLoader();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: appConfig.apiUrl.activityList,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader();
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader();
        reject(getPureJSON(error.response || error));
      });
  });
};

const getActivityById = (obj) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const { spacode } = obj;
    var data = JSON.stringify({
      spacode: spacode,
    });
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.activityById}?spacode=${spacode}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const getCategoryTitle = (id) => {
  startLoader(1);
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${appConfig.apiUrl.getActivitiesByCategory}/${id}`,
      // url: `${appConfig.apiUrl.getActivitiesByChannel}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        handleAPIError(error.status);
        reject(getPureJSON(error.response || error));
      });
  });
};

const updateActivityData = (obj) => {
  startLoader(1);
  const authToken = getUserToken();
  return new Promise((resolve, reject) => {
    var data = JSON.stringify(obj);

    var config = {
      method: "post",
      url: obj?.spacode
        ? `${appConfig.apiUrl.editActivity}`
        : `${appConfig.apiUrl.saveActivity}`,
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        stopLoader(1);
        resolve(response.data);
      })
      .catch(function (error) {
        stopLoader(1);
        reject(getPureJSON(error.response || error));
      });
  });
};
export {
  getActivityList,
  getActivityById,
  updateActivityData,
  getCategoryTitle,
};
