import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TextField, Button, Tabs, Tab } from "@mui/material";
import TabPanel from "../../components/TabPanel";
import { startLoader, stopLoader } from "../../libs/utils";
import Swal from "sweetalert2";
import {
  cancelTxnService,
  getBankUserInfo,
  getUserAchData,
  getUserRemittanceTxn,
  updateTxnRecoveryData,
} from "../../services/user";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { updateUserRiskProfile } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { setUserAchData } from "../../redux/slices/neobankAppConfigSlice";

const BTN_STYLE = { height: "48px", marginLeft: "8px" };
const LINK_LEFT_STYLE = { marginLeft: "4px", fontSize: "18px" };

function SearchUsers() {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const emailRef = useRef();

  const [email, setEmail] = useState("");
  const [userTransactions, setUserTransactions] = useState([]);
  const [achData, setAchData] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [isDynamicTable, setIsDynamicTable] = useState(false);
  const [bankUserInfo, setBankUserInfo] = useState({});
  const [riskScore, setRiskScore] = useState("");

  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email")?.replace(/\ /g, "+");

  const handleChange = (event, newValue) => {
    sessionStorage.setItem("pageIndex", newValue);
    const value = Number(sessionStorage.getItem("pageIndex"));
    if (value) {
      setCurrentTab(value);
    } else {
      setCurrentTab(newValue);
    }
  };
  useEffect(() => {
    const value = Number(sessionStorage.getItem("pageIndex"));
    if (value) {
      setCurrentTab(value);
    } else {
      setCurrentTab(currentTab);
    }
  }, []);

  const { user } = bankUserInfo || {};
  const {
    mobileNo,
    userStatus,
    userOptedInBanking,
    debitCardActivated,
    debitCardDelivered,
    userOptedInRemittance,
    userBlocked,
    cipTag,
    customerId,
    userCategory,
  } = user || {};

  const [userType, setUserType] = useState(userCategory);
  const userData = (type) => {
    const mapping = [
      { key: "Email", value: email },
      { key: "Mobile No", value: mobileNo },
      { key: "Status", value: userStatus },
      { key: "Debit Card activated", value: debitCardActivated.toString() },
      { key: "Debit Card delivered", value: debitCardDelivered.toString() },
      { key: "Synapse CIP tag", value: cipTag },
      { key: " Remittance", value: userOptedInRemittance.toString() },
      { key: "Neobank", value: userOptedInBanking.toString() },
      { key: "Blocked", value: userBlocked.toString() },
    ];
    return mapping.map((data) => {
      return (
        <div className="listBox">
          <div className="listItem">
            <div className="itemHead">{data.key}</div>
            <div>{data.value}</div>
          </div>
        </div>
      );
    });
  };
  const columns = [
    {
      id: "transaction_tracking_id",
      label: "Tracking ID",
      minWidth: 150,
      align: "left",
      enableSort: true,
    },
    {
      id: "txn_creation_date",
      label: "Txn Creation Date",
      minWidth: 100,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "beneficiary_details",
      label: "Beneficiary Details",
      minWidth: 80,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "amount_usd",
      label: "Amount USD",
      minWidth: 150,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "amount_inr",
      label: "Amount INR",
      minWidth: 150,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "fx_rate",
      label: "FX Rate",
      minWidth: 100,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "current_state",
      label: "Current State",
      minWidth: 100,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "strategy",
      label: "Strategy",
      minWidth: 100,
      sortable: true,
      align: "center",
      enableSort: true,
    },
    {
      id: "Action",
      label: "Action",
      minWidth: 200,
      align: "center",
    },
    {
      id: "Cancel",
      label: "Cancel Txn",
      minWidth: 200,
      align: "center",
    },
  ];
  const achColumns = [
    {
      id: "transaction_date",
      label: "Transaction Date",
      minWidth: 30,
      enableSort: true,
    },
    {
      id: "amount",
      label: "Amount",
      minWidth: 35,
      align: "left",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 35,
      align: "left",
    },
    {
      id: "status_description",
      label: "Description",
      minWidth: 35,
      align: "left",
    },
    {
      id: "type",
      label: "Type",
      minWidth: 35,
      align: "left",
    },
    {
      id: "request_id",
      label: "Request Id",
      minWidth: 35,
      align: "left",
    },
    {
      id: "external_request_id",
      label: "External Request Id",
      minWidth: 35,
      align: "left",
    },
    {
      id: "bank_name",
      label: "Bank Name",
      minWidth: 35,
      align: "left",
    },
    { id: "bank_mask", label: "Bank Mask", minWidth: 35, align: "left" },
    {
      id: "partner",
      label: "Partner",
      minWidth: 35,
      align: "left",
    },
    {
      id: "partner_transaction_id",
      label: "Partner Transaction Id",
      minWidth: 45,
      align: "left",
    },
    {
      id: "retry_details",
      label: "Retry Details",
      minWidth: 70,
      align: "left",
    },
  ];

  const achDataGenerator = (data) => {
    const { retry_details } = data || {};
    return {
      ...data,
      retry_details: (
        <>
          {retry_details ? (
            <div className="d-flex align-items-center txnId">
              <Link
                className="text-dark txnId"
                to={`/app/user-ach-data/retry-details/${emailParam}`}
                state={{ request_id: data.request_id }}
              >
                Show details
              </Link>
            </div>
          ) : (
            ""
          )}
        </>
      ),
      transaction_date: moment
        .unix(data.transaction_date)
        .format("MMM DD, YYYY"),
    };
  };
  const searchUser = () => {
    startLoader();
    setCurrentTab(0);
    if (email) {
      getBankUserInfo(email)
        .then((response) => {
          setBankUserInfo(response);
          stopLoader();
          if (response?.error) {
            setUserTransactions([]);
            setAchData({});
            Swal.fire({
              title: "Oops",
              text: response.error?.message,
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
            return;
          }
          if (response.success) {
            navigation(`/app/search-users?email=${email}`);
          }
        })
        .catch((error) => {
          stopLoader();
          if (error.status === 404) {
            setBankUserInfo({});
            setAchData({});
            setUserTransactions([]);
            Swal.fire({
              title: "Oops",
              text: "User not found",
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
          } else {
            Swal.fire({
              title: "Oops",
              text: "Something went wrong",
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
          }
        });
      stopLoader();
    } else {
      stopLoader();
      Swal.fire({
        title: "Oops",
        text: "Please fill email",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    }
  };

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const handleUpdate = () => {
    dispatch(updateUserRiskProfile({ customerId, riskScore }));
  };

  const handleRiskProfile = (e) => {
    const value = e.target.value;
    setRiskScore(value);
  };

  const handleCancelTxn = (transaction_ref_id) => {
    if (transaction_ref_id) {
      startLoader();
      cancelTxnService(transaction_ref_id)
        .then((response) => {
          if (response?.error) {
            stopLoader();
            Swal.fire({
              title: "Oops",
              text: response.error.message,
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
            return;
          }
          if (response.success) {
            Swal.fire({
              title: "Success",
              text: response.message,
              icon: "success",
              confirmButtonText: "Ok",
            }).then((result) => {
              getUserRemittanceTxn(emailRef.current, false)
                .then((res) => {
                  if (res?.success) {
                    setUserTransactions(
                      res.transactions?.map((data) => dataGenerator(data))
                    );
                    stopLoader();
                  }
                })
                .catch((err) => {
                  stopLoader();
                });
            });
            return;
          }
        })
        .catch((error) => {
          stopLoader();
          setBankUserInfo({});
          Swal.fire({
            title: "Oops",
            text: error.data?.error?.message,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          return;
        });
    }
  };
  const handleUnblockUser = (transaction_ref_id) => {
    if (transaction_ref_id) {
      startLoader();
      updateTxnRecoveryData(transaction_ref_id)
        .then((response) => {
          stopLoader();
          if (response?.error) {
            Swal.fire({
              title: "Oops",
              text: response.error.message,
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
            return;
          }
          if (response.success) {
            Swal.fire({
              title: "Sucess",
              text: response.message,
              icon: "success",
              confirmButtonText: "Ok",
            }).then((result) => {});
            return;
          }
        })
        .catch((error) => {
          stopLoader();
          setBankUserInfo({});
          Swal.fire({
            title: "Oops",
            text: error.data?.error?.message,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
          return;
        });
    }
  };

  const dataGenerator = (data) => {
    return {
      transaction_tracking_id: (
        <div className="d-flex align-items-center txnId">
          <Link
            className="text-dark txnId"
            to={`/app/user-txn-details/${emailParam}/${data.transaction_ref_id}`}
          >
            {data.transaction_tracking_id}
          </Link>
          <span style={LINK_LEFT_STYLE}>
            <i className="fa fa-angle-double-right"></i>
          </span>
        </div>
      ),
      txn_creation_date: moment(data.transaction_created_date).format("LL"),
      beneficiary_details: data.beneficiary_name,
      amount_usd: data.amount_usd,
      amount_inr: data.amount_inr,
      fx_rate: data.fx_rate,
      current_state: data.transaction_current_status,
      current_state_time: null,
      current_state_txn_id: null,
      overall_state: null,
      strategy: data.remittance_transaction_strategy,
      signal_transaction_id: data.signal_transaction_id,
      Action: (
        <>
          {data.crm_initiated && (
            <Link style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                size="small"
                color="warning"
                onClick={() => handleUnblockUser(data.transaction_ref_id)}
              >
                Paid by Stripe
              </Button>
            </Link>
          )}
        </>
      ),
      Cancel: (
        <>
          {data.cancellation_eligible && (
            <Link style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                size="small"
                color="warning"
                onClick={() => handleCancelTxn(data.transaction_ref_id)}
              >
                Cancel Transaction
              </Button>
            </Link>
          )}
        </>
      ),
    };
  };
  useEffect(() => {
    if (emailParam) {
      startLoader();

      getBankUserInfo(emailParam)
        .then((response) => {
          stopLoader();
          if (response?.error) {
            setUserTransactions([]);
            Swal.fire({
              title: "Oops",
              text: response.error?.message,
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
            return;
          }
          if (response.success) {
            setBankUserInfo(response);
            setRiskScore(response?.user?.riskProfile);
            getUserRemittanceTxn(response.user.email)
              .then((res) => {
                emailRef.current = response.user.email;
                if (res.success) {
                  setUserTransactions(
                    res.transactions?.map((data) => dataGenerator(data))
                  );
                }
                getUserAchData(response.user.customerId)
                  .then((response) => {
                    setAchData(
                      response.data.map((data) => achDataGenerator(data))
                    );
                    dispatch(setUserAchData(response));
                  })
                  .catch((error) => {});
              })
              .catch((error) => {
                stopLoader();
                Swal.fire({
                  title: "Oops",
                  text: error?.message,
                  icon: "warning",
                  confirmButtonText: "Ok",
                }).then((result) => {});
                return;
              });
          }
        })
        .catch((error) => {
          stopLoader();
        });
    }
    setEmail(emailParam);
  }, [emailParam, location]);

  const handleUpdateInterest = (user_type) => {
    if (!userType) {
      Swal.fire({
        title: "Oops",
        text: "Please select valid value",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
      return;
    }
    dispatch(updateUserRiskProfile({ customerId, user_type: userType }));
  };

  return (
    <>
      <Box
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
        sx={{ px: 6 }}
      >
        <Typography
          component="h4"
          variant="h4"
          color="inherit"
          noWrap
          sx={{ mb: 2, fontSize: "22px", fontWeight: "700" }}
        >
          Search User
        </Typography>

        <div className="searchUserHeader mb-5">
          <div>
            <TextField
              style={{ width: "280px" }}
              label="Email/Phone"
              variant="standard"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              onClick={() => searchUser()}
              style={BTN_STYLE}
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </div>
        {customerId && (
          <>
            <Box sx={{ mt: 3, borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={currentTab}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{}}
              >
                <Tab
                  className="tabStyle"
                  label="User Details"
                  {...a11yProps(0)}
                />

                <Tab
                  className="tabStyle"
                  label="Remittance History"
                  {...a11yProps(1)}
                />

                <Tab
                  className="tabStyle"
                  label="Update Risk Profile"
                  {...a11yProps(2)}
                />
                <Tab
                  className="tabStyle"
                  label="Update Higher Interest"
                  {...a11yProps(3)}
                />
                <Tab
                  className="tabStyle"
                  label="Ach Ledger"
                  {...a11yProps(4)}
                />
              </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
              {userData()}
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              {userTransactions.length ? (
                <CustomTable
                  setDynamicTable={setDynamicTable}
                  columns={columns}
                  data={userTransactions}
                />
              ) : (
                "No Data Found"
              )}
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <div className="mainContainer">
                  <div className="riskInputContainer">
                    <label className="riskLabel">Risk Profile</label>
                    <input
                      type="number"
                      value={riskScore}
                      onChange={(e) => handleRiskProfile(e)}
                      className="riskInput"
                      min={1}
                      max={9}
                    ></input>
                  </div>

                  <div className="riskInputContainer">
                    <label className="riskLabel">Action</label>
                    <Button
                      variant="contained"
                      size="small"
                      color="warning"
                      onClick={() => handleUpdate()}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <div className="mainContainer">
                  <div className="riskInputContainer">
                    <label className="riskLabel">
                      Update Higher Interest rate
                    </label>
                    <select
                      value={userType || userCategory}
                      onChange={(e) => setUserType(e.target.value)}
                      className="form-select"
                    >
                      <option value="">SELECT</option>
                      <option value="NORMAL">NORMAL</option>
                      <option value="HIGH_INTEREST">HIGH_INTEREST</option>
                    </select>
                  </div>
                  <div className="riskInputContainer">
                    <label className="riskLabel">Action</label>
                    <Button
                      variant="contained"
                      size="small"
                      color="warning"
                      onClick={() => handleUpdateInterest(userType)}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={4}>
              {achData.length ? (
                <CustomTable
                  setDynamicTable={setDynamicTable}
                  columns={achColumns}
                  data={achData}
                />
              ) : (
                "No Data Found"
              )}
            </TabPanel>
          </>
        )}
      </Box>
    </>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default SearchUsers;
