import Box from "@mui/material/Box";
import { Button } from "@mui/material";

import { useDispatch } from "react-redux";
import { updateUserRiskProfile } from "../../../redux/slices/authSlice";

const RiskProfile = ({ riskScore, customerId, setRiskScore }) => {
  const dispatch = useDispatch();

  const handleRiskProfile = (e) => {
    const value = e.target.value;
    setRiskScore(value);
  };

  const handleUpdate = () => {
    dispatch(updateUserRiskProfile({ customerId, riskScore }));
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <div className="mainContainer">
        <div className="riskInputContainer">
          <label className="riskLabel">Risk Profile</label>
          <input
            type="number"
            value={riskScore}
            onChange={(e) => handleRiskProfile(e)}
            className="riskInput"
            min={1}
            max={9}
          ></input>
        </div>

        <div className="riskInputContainer">
          <label className="riskLabel">Action</label>
          <Button
            variant="contained"
            size="small"
            color="warning"
            onClick={() => handleUpdate()}
          >
            Update
          </Button>
        </div>
      </div>
    </Box>
  );
};
export default RiskProfile;
