export const ROLES = [
  "ROLE_ADMIN",
  "ROLE_NEOBANK_SUPPORT",
  "ROLE_REWARD_SUPPORT",
];

export const OFFER_VISIBILITY_OPTIONS = [
  {
    label: "Open Offer",
    value: "OPEN_OFFER",
    default: false,
  },
  {
    label: "Card Linked Offer",
    value: "CARD_LINKED_OFFER",
    default: false,
  },
  {
    label: "Bank Linked Offer",
    value: "BANK_LINKED_OFFER",
    default: true,
  },
  {
    label: "Bank and Card Linked Offer",
    value: "BANK_CARD_LINKED_OFFER",
    default: false,
  },
];
export const IMAGE_LABEL_MAPPING = [
  { label: "Image", value: "IMAGE" },
  { label: "Logo", value: "LOGO" },
  { label: "Big Image", value: "BIG_IMAGE" },
  { label: "Small Image", value: "SMALL_IMAGE" },
  { label: "Small Logo Image", value: "SMALL_LOGO_IMAGE" },
  { label: "Offer Wall Logo Card Image", value: "OFFER_WALL_LOGO_CARD_IMAGE" },
];

export const API_KEY_MAPPING = {
  IMAGE: "image",
  LOGO: "logo",
  BIG_IMAGE: "bigImage",
  SMALL_IMAGE: "smallImage",
  SMALL_LOGO_IMAGE: "smallLogoImage",
  OFFER_WALL_LOGO_CARD_IMAGE: "offerWallLogoCardImage",
};

export const REFERRAL_WALLET_STATUS_MAPPING = {
  0: "Referral Eligible",
  1: "Document Received",
  2: "Referral Blocked",
  3: "Annual Referral Limit Reached",
};
export const ROUTE_PERSMISSION = [
  {
    path: "/app/offers",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-partner-promotional-offers",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-offer-spathre",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-offer-groups",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    title: "Transactions",
    path: "/app/transactions",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/plaidbanks",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/pending-bank",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/user",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-cms-users",
    allowedOnROles: ["ROLE_ADMIN"],
  },
  {
    path: "/app/cms-user",
    allowedOnROles: ["ROLE_ADMIN"],
  },
  {
    path: "/app/cashback",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/pending-cashback",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-category",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-how-it-works",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-faq",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-app-config",
    allowedOnROles: ["ROLE_ADMIN"],
  },
  {
    path: "/app/static-json",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/pathp-cards-json",
    allowedOnROles: ["ROLE_ADMIN"],
  },
  {
    path: "/app/process-transactions",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/tiers",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-bonus-referal",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-benefits",
    allowedOnROles: ["ROLE_ADMIN"],
  },
  {
    path: "/app/banks",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-bank-add-rule",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-merchants",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-spathres",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/list-transaction-merchant-mapping-audit/0/50",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/rules",
    allowedOnROles: ["ROLE_ADMIN"],
  },
  {
    path: "/app/affiliate-partner",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/image-upload",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
  },
  {
    path: "/app/neobank-app-config",
    allowedOnROles: ["ROLE_ADMIN"],
  },
  {
    path: "/app/updated-search-users",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
  },
  {
    path: "/app/search-users",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
  },
  {
    path: "/app/remittance-return",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
  },
  {
    path: "/app/fx-rate-campaign",
    allowedOnROles: ["ROLE_ADMIN"],
  },
  {
    path: "/app/fx-rate-compare",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
  },
  {
    path: "/app/blocked-remittance-users",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
  },
  {
    path: "/app/blocked-user",
    allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
  },
];

export const MONTH_MAPPING = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sept",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};
export const allowedRegex = /^(?=.*[0-9])[- +()0-9]+$/;
