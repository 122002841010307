import CustomTable from "../../../components/CustomTable";

const AchLedger = ({ achData = [] }) => {
  const achColumns = [
    {
      id: "transaction_date",
      label: "Transaction Date",
      minWidth: 30,
      enableSort: true,
    },
    {
      id: "amount",
      label: "Amount",
      minWidth: 35,
      align: "left",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 35,
      align: "left",
    },
    {
      id: "status_description",
      label: "Description",
      minWidth: 35,
      align: "left",
    },
    {
      id: "type",
      label: "Type",
      minWidth: 35,
      align: "left",
    },
    {
      id: "request_id",
      label: "Request Id",
      minWidth: 35,
      align: "left",
    },
    {
      id: "external_request_id",
      label: "External Request Id",
      minWidth: 35,
      align: "left",
    },
    {
      id: "bank_name",
      label: "Bank Name",
      minWidth: 35,
      align: "left",
    },
    { id: "bank_mask", label: "Bank Mask", minWidth: 35, align: "left" },
    {
      id: "partner",
      label: "Partner",
      minWidth: 35,
      align: "left",
    },
    {
      id: "partner_transaction_id",
      label: "Partner Transaction Id",
      minWidth: 45,
      align: "left",
    },
    {
      id: "retry_details",
      label: "Retry Details",
      minWidth: 70,
      align: "left",
    },
  ];

  return achData.length ? (
    <CustomTable columns={achColumns} data={achData} />
  ) : (
    "No Data Found"
  );
};

export default AchLedger;
