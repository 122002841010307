import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import RuleIcon from "@mui/icons-material/Rule";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { hasRole } from "../../../libs/utils";
import appConfig from "../../../config/app.config";

const menus = () => {
  const tcItems = [
    {
      icon: <LocalOfferIcon />,
      title: "Offers",
      items: [
        {
          title: "Offers",
          to: "/app/offers",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Partner Promotional Offers",
          to: "/app/list-partner-promotional-offers",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Offers Stores",
          to: "/app/list-offer-store",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Offers Groups",
          to: "/app/list-offer-groups",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
      ],
    },
    {
      icon: <AccountBalanceIcon />,
      title: "Transactions",
      items: [
        {
          title: "Transactions",
          to: "/app/transactions",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Plaid Banks",
          to: "/app/plaidbanks",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Pending Bank Request",
          to: "/app/pending-bank",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
      ],
    },
    {
      icon: <AccountBoxIcon />,
      title: "Users",
      items: [
        {
          title: "User",
          to: "/app/user",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Cms User",
          to: "/app/list-cms-users",
          allowedOnROles: ["ROLE_ADMIN"],
        },
      ],
    },
    {
      icon: <AccountBalanceWalletIcon />,
      title: "Wallet",
      items: [
        {
          title: "Cashback",
          to: "/app/cashback",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Cashback Request",
          to: "/app/pending-cashback",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
      ],
    },
    {
      icon: <RuleIcon />,
      title: "Utilities",
      items: [
        // {
        //   title: "Advertiser Category",
        //   to: "/app/list-advertiser-category",
        // },
        {
          title: "Category",
          to: "/app/list-category",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "How it Works",
          to: "/app/list-how-it-works",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "FAQ",
          to: "/app/list-faq",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        // {
        //   title: "Card Sequence",
        //   to: "/app/list-card-sequence",
        // },
        // {
        //   title: "Rewards FAQ",
        //   to: "/app/list-rewards-faq",
        // },
        // {
        //   title: "Chart",
        //   to: "/app/chart",
        // },
        {
          title: "Applicaton Configuration",
          to: "/app/list-app-config",
          allowedOnROles: ["ROLE_ADMIN"],
        },
        {
          title: "Static JSON",
          to: "/app/static-json",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Top Cards Static JSON",
          to: "/app/top-cards-json",
          allowedOnROles: ["ROLE_ADMIN"],
        },
        {
          title: "Process Transactions",
          to: "/app/process-transactions",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "New Tiers",
          to: "/app/tiers",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Bonus Referal",
          to: "/app/list-bonus-referal",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Tier Benefits",
          to: "/app/list-benefits",
          allowedOnROles: ["ROLE_ADMIN"],
        },
        {
          title: "Bank",
          to: "/app/banks",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Bank Add Rule Engine",
          to: "/app/list-bank-add-rule",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Merchants",
          to: "/app/list-merchants",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Stores",
          to: "/app/list-stores",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Transaction Merchant Mapping",
          to: "/app/list-transaction-merchant-mapping-audit/0/50",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Customer Scoring Rules",
          to: "/app/rules",
          allowedOnROles: ["ROLE_ADMIN"],
        },
        {
          title: "Affiliate Partner",
          to: "/app/affiliate-partner",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
        {
          title: "Image Upload",
          to: "/app/image-upload",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },

        // {
        //   title: "Times Point Activity",
        //   to: "/app/list-activities",
        //   allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        // },
        {
          title: "Cashback Processing",
          to: "/app/cashback-processing",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
      ],
    },
    {
      icon: <AccountBalanceWalletIcon />,
      title: "Neobank",
      items: [
        {
          title: "Application configuaration List",
          to: "/app/neobank-app-config",
          allowedOnROles: ["ROLE_ADMIN"],
        },
        {
          title: "Search Users",
          to: "/app/search-users",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
        },
        {
          title: "Advance Search Users",
          to: "/app/updated-search-users",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
        },
        {
          title: "Remittance Return",
          to: "/app/remittance-return",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
        },
        {
          title: "FX Rate Campaign",
          to: "/app/fx-rate-campaign",
          allowedOnROles: ["ROLE_ADMIN"],
        },
        {
          title: "FX Rate Compare",
          to: "/app/fx-rate-compare",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
        },
        {
          title: "Remittance Blocked Users",
          to: "/app/blocked-remittance-users",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
        },
        {
          title: "Remittance User Risk Configurations",
          to: "/app/risk-configuration",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
        },

        {
          title: "Neobank User Risk Configurations",
          to: "/app/neobank-risk-configuration",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
        },
        {
          title: "Search Tracking Id",
          to: "/app/search-transactions",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_NEOBANK_SUPPORT"],
        },
      ],
    },
    {
      icon: <MonetizationOnIcon />,
      title: "Abound Cash",
      items: [
        {
          title: "Activity",
          // to: "/app/list-activitiesnew",
          to: "/app/list-activities",
          allowedOnROles: ["ROLE_ADMIN", "ROLE_REWARD_SUPPORT"],
        },
      ],
    },
  ];
  const userRoles = hasRole() || [];
  for (let i = 0; i < tcItems.length; i++) {
    for (let j = 0; j < tcItems[i].items.length; j++) {
      const allowedOnROles = tcItems[i].items[j].allowedOnROles;
      const hasAccess = allowedOnROles.some((role) =>
        userRoles?.includes(role)
      );
      if (!hasAccess) {
        tcItems[i].items.splice(j, 1);
        j--;
      }
    }
    if (!tcItems[i].items.length) {
      tcItems.splice(i, 1);
      i--;
    }
  }

  return tcItems;
};

export { menus };
