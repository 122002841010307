import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { handleApiSuccess } from "../../../libs/utils";
import {
  getActivityById,
  getCategoryTitle,
  updateActivityData,
} from "../../../services/timesPoint/timesPointActivity";
import moment from "moment";
import {
  Form,
  Input,
  Select,
  Tooltip,
  Button,
  Row,
  Col,
  Radio,
  InputNumber,
  DatePicker,
  Modal,
  Divider,
  Switch,
  Collapse,
} from "antd";
import request from "superagent";

const { Panel } = Collapse;
const { Option } = Select;

function CreateActivityOld(props) {
  const navigate = useNavigate();

  const [state, setState] = useState({
    visible: false,
    submitting: false,
    titles: [],
    reloadPage: false,
    platforms: ["web", "msite", "android", "ios"],
  });

  const [activityData, setActivityData] = useState(null);

  const formRef = useRef();
  const { spacode } = useParams();

  useEffect(() => {
    if (spacode !== "0") {
      getActivityById({ spacode })
        .then((response) => {
          const { start_date, end_date } = response?.activitiy;
          console.log("response", response);
          if (response?.activitiy) {
            let initialValues = {};
            let defaultActiveKey = ["web"];
            if (activityData) {
              const { desc, pfmDetails = [] } = activityData || {};
              initialValues = activityData;
              initialValues.activityName = desc;
              initialValues.actval = desc;
              defaultActiveKey = [];
              let pfmdel = pfmDetails;
              initialValues.frequency = "0";
              initialValues.category = "4869021388202283008";
              for (let i = 0; i < pfmdel.length; i++) {
                let platform = pfmdel[i].pfm || "web";
                defaultActiveKey.push(platform);
                for (let key in pfmdel[i]) {
                  let txt = platform + "_" + key;
                  initialValues[txt] = pfmdel[i][key];
                }
              }
              formRef?.current?.setFieldsValue({
                ...initialValues,
              });
            }
            console.log("ini", initialValues);
            setActivityData({
              ...response.activitiy,
              start_date: start_date
                ? moment(start_date).local().format("YYYY-MM-DD HH:mm")
                : null,
              end_date: end_date
                ? moment(end_date).local().format("YYYY-MM-DD HH:mm")
                : null,
            });
          }
        })
        .catch((error) => {});
    }
  }, [spacode]);

  const submitProduct = (values) => {
    let pfmDetailsArr = [];
    let platforms = state.platforms;
    for (let i = 0; i < platforms.length; i++) {
      let poitPfm = platforms[i] + "_pts";
      var obj = {};
      for (let key in values) {
        let firstKey = key.split("_");
        if (firstKey[0] === platforms[i]) {
          if (values[poitPfm]) {
            obj[firstKey[1]] = values[key];
          }
        }
      }
      if (Object.keys(obj).length > 0) {
        pfmDetailsArr.push(obj);
      }
    }

    // for (let i = 0; i < platforms.length; i++) {
    //   for (let key in values) {
    //     let firstKey = key.split("_");
    //     if (firstKey[0] == platforms[i]) {
    //         delete values[key];
    //     }
    //   }
    // }
    if (pfmDetailsArr.length === 0) {
      setState((prevState) => ({
        ...prevState,
        visible: true,
        message: "Please Enter atleast one platform details",
      }));
    } else {
      let formData = new FormData();
      values.pfmDetails = pfmDetailsArr;
      if (props.selectedActivity) {
        values.spacode = props.selectedActivity.spacode;
      }

      for (let key in values) {
        if (key === "pfmDetails") {
          for (let j = 0; j < values[key].length; j++) {
            for (let key1 in values[key][j]) {
              if (key1 === "image") {
                let idv = values[key][j].pfm + "_image";
                let files = document.getElementById(idv).files;
                if (files[0]) {
                  formData.set("pfmDetails[" + j + "]." + key1, files[0]);
                }
              } else if (values[key][j][key1] === undefined) {
                formData.set("pfmDetails[" + j + "]." + key1, "");
                if (key1 === "orderSequence") {
                  formData.set("pfmDetails[" + j + "]." + key1, "999");
                }
              } else {
                formData.set(
                  "pfmDetails[" + j + "]." + key1,
                  values[key][j][key1]
                );
              }
            }
          }

          //formData.set('productImage270X170',files[0] )
        } else if (values[key] === undefined) {
          formData.set(key, "");
        } else {
          formData.set(key, values[key]);
        }
      }
      console.log("formData", values);
      const updatedValues = { ...activityData, ...values };
      updateActivityData(updatedValues)
        .then((response) => {
          console.log("updateActivityData", response);
          handleApiSuccess(response.success, response.message);
          navigate("/app/list-activities");
        })
        .catch((error) => {});
    }
  };

  const loadCategoryTitle = (id) => {
    console.log(id);
    if (id) {
      getCategoryTitle(id)
        .then((res) => {
          console.log("loadCategoryTitle", loadCategoryTitle);
          setState((prevState) => ({ ...prevState, titles: res.body }));
        })
        .catch((error) => {});
    }
  };

  const renderCategory = () => {
    const categories = [
      {
        name: "others",
        ids: "4869021388202283008",
        id: 4869021388202283008,
        desc: "Others",
      },
    ];
    return (categories || []).map((item, index) => {
      return (
        <Option value={item.ids} key={item.ids}>
          {item.desc}
        </Option>
      );
    });
  };

  const renderPlatform = () => {
    return (state.platforms || []).map((item, index) => {
      return (
        <Panel header={`Platform ${item}`} key={item}>
          <Row gutter={[20, 0]} key={index}>
            <Form.Item
              name={`${item}_pfm`}
              initialValue={item}
              label=""
              rules={[{ required: true }]}
            >
              <Input type={"hidden"} />
            </Form.Item>
            <Col span={8}>
              <Form.Item name={`${item}_dlink`} label="Deep Link">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={`${item}_pts`}
                label={
                  <Tooltip
                    placement="topLeft"
                    title="Enter number of points which user will earn by completing the activity."
                  >
                    Points ?
                  </Tooltip>
                }
              >
                <InputNumber />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                initialValue={false}
                name={`${item}_status`}
                valuePropName="checked"
                label="Active/Inactive"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={`${item}_starAct`}
                valuePropName="checked"
                initialValue={false}
                label="Star Activity"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={`${item}_orderSequence`} label="Sequence Order">
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={`${item}_subTitle`} label="Sub-Title">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={`${item}_actionText`}
                label="Action Button Title"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={`${item}_image`} label="Upload Image">
                <Input type={"file"} id={`${item}_image`} />
              </Form.Item>
            </Col>
            <Form.Item name={`${item}_imageUrl`} label="">
              <Input type={"hidden"} />
            </Form.Item>
          </Row>
        </Panel>
      );
    });
  };

  const handleActivityChange = (id) => {
    //checkActivity?activityId=-7777185503276775464
    let that = this;
    request.get("/checkActivity?activityId=" + id).end(function (err, res) {
      if (res.body.result) {
        that.setState({
          visible: true,
          message: res.body.message,
        });
      }
    });
  };

  const renderTitle = () => {
    return (state.titles || []).map((item, index) => {
      return (
        <Option value={item.activityStr} key={item.activityStr}>
          {item.description}
        </Option>
      );
    });
  };

  const handleOk = () => {
    setState((prevState) => ({
      ...prevState,
      visible: false,
    }));
    if (state.reloadPage) {
      window.location.reload();
    }
  };

  const handleCancel = () => {
    setState((prevState) => ({
      ...prevState,
      visible: false,
    }));
    if (state.reloadPage) {
      window.location.reload();
    }
  };

  let initialValues = {};
  let defaultActiveKey = ["web"];
  if (activityData) {
    const { desc, pfmDetails = [] } = activityData || {};
    // initialValues = JSON.parse(JSON.stringify(props.selectedActivity));
    initialValues = activityData;
    initialValues.activityName = desc;
    initialValues.actval = desc;
    defaultActiveKey = [];
    let pfmdel = pfmDetails;
    initialValues.frequency = "0";
    initialValues.category = "4869021388202283008";
    for (let i = 0; i < pfmdel.length; i++) {
      let platform = pfmdel[i].pfm || "web";
      defaultActiveKey.push(platform);
      for (let key in pfmdel[i]) {
        let txt = platform + "_" + key;
        initialValues[txt] = pfmdel[i][key];
      }
    }
    formRef?.current?.setFieldsValue({
      ...initialValues,
    });
  }
  console.log("ini", initialValues);
  const isEditMode = !!activityData;
  return (
    <>
      <Box sx={{ ml: "4", pt: "14 !important", pb: "5", px: 3 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          <Link to={`/app/list-activities`}>
            <ArrowBackIcon
              fontSize="large"
              l
              color="secondary"
              sx={{ background: "#040721", p: 1, borderRadius: "50%" }}
            />
          </Link>
          <Typography
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            color={"#3d3d3d"}
          >
            {spacode !== "0" ? "Edit Activity" : "Add Activity"}
          </Typography>
        </div>
        <Box sx={{ background: "#ffffff", p: 2, mb: 4, pb: 4 }}>
          <div>
            <Form
              style={{ marginTop: "35px" }}
              layout={"vertical"}
              ref={formRef}
              name="control-ref"
              onFinish={submitProduct}
              initialValues={initialValues}
            >
              <Row gutter={[20, 0]}>
                <Col span={8}>
                  <Form.Item
                    name="category"
                    rules={[{ required: true }]}
                    label={
                      <Tooltip
                        placement="topLeft"
                        title="Same type of activities are grouped together in the category. Choose the best matching category."
                      >
                        Activity Category ?
                      </Tooltip>
                    }
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={loadCategoryTitle}
                      disabled={isEditMode}
                    >
                      {renderCategory()}
                    </Select>
                  </Form.Item>
                </Col>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.category !== currentValues.category
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("category") ===
                      "4869021388202283008" ? (
                      <Col span={8}>
                        <Form.Item
                          name="actval"
                          rules={[{ required: true }]}
                          label={
                            <Tooltip
                              placement="topLeft"
                              title="Select activity name from the list or create your own."
                            >
                              Title ?
                            </Tooltip>
                          }
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col span={8}>
                        <Form.Item
                          name="activityName"
                          rules={[{ required: true }]}
                          label={
                            <Tooltip
                              placement="topLeft"
                              title="Select activity name from the list or create your own."
                            >
                              Title ?
                            </Tooltip>
                          }
                        >
                          <Select
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleActivityChange}
                            disabled={isEditMode}
                          >
                            {renderTitle()}
                          </Select>
                        </Form.Item>
                      </Col>
                    );
                  }}
                </Form.Item>

                <Col span={8}>
                  <Form.Item name="clickEvent" label="Click Event">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Collapse defaultActiveKey={defaultActiveKey}>
                    {renderPlatform()}
                  </Collapse>
                </Col>
                <Divider orientation="left"></Divider>

                <Col span={6}>
                  <Form.Item
                    name="frequency"
                    label={
                      <Tooltip
                        placement="topLeft"
                        title="Any activity is performed either once in a life time or on regular basis. Choose 'One time' for once in a life time activity or 'Continous' for regular activity."
                      >
                        Frequency ?
                      </Tooltip>
                    }
                    rules={[{ required: true }]}
                  >
                    <Radio.Group disabled={isEditMode}>
                      <Radio value={"1"}>One Time</Radio>
                      <Radio value={"0"}>Continuous</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.frequency !== currentValues.frequency
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("frequency") === 0 ? (
                      <>
                        <Col span={6}>
                          <Form.Item
                            name="maxCap"
                            label={
                              <Tooltip
                                placement="topLeft"
                                title="a) Limit: Set maximum limit on number of times the user can earn points by performing the activity. b) Duration: Specify time for which 'Limit' will be applicable e.g. If Max Cap Limit is 7 and Duration is 1 day. And any user performs 10 activity in 1 day then only 7 will be eligible for earning points remaining 3 won't."
                              >
                                Max Cap Limit ?
                              </Tooltip>
                            }
                            initialValue={"0"}
                            rules={[{ required: true }]}
                          >
                            <InputNumber />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name="maxCapDuration"
                            label={
                              <Tooltip
                                placement="topLeft"
                                title="a) Limit: Set maximum limit on number of times the user can earn points by performing the activity. b) Duration: Specify time for which 'Limit' will be applicable e.g. If Max Cap Limit is 7 and Duration is 1 day. And any user performs 10 activity in 1 day then only 7 will be eligible for earning points remaining 3 won't."
                              >
                                Max Cap Duration (Days) ?
                              </Tooltip>
                            }
                            initialValue={"0"}
                            rules={[{ required: true }]}
                          >
                            <InputNumber />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name="creditLimit"
                            label={
                              <Tooltip
                                placement="topLeft"
                                title="It is the time gap required between 2 successive instances of the same activity to make it legitimate to get rewarded e.g. if credit limit is 30 seconds for comment posting. And any user posts 2 comments in 15 seconds gap then he won't earn point for 2nd comment."
                              >
                                Credit limit (sec) ?
                              </Tooltip>
                            }
                            initialValue={"0"}
                            rules={[{ required: true }]}
                          >
                            <InputNumber />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null;
                  }}
                </Form.Item>
                <Col span={24}>
                  <Collapse accordion defaultActiveKey={["1"]}>
                    <Panel header="Additional Configuration" key="1">
                      <Row gutter={[20, 0]} key={"addiConfig"}>
                        <Col span={4}>
                          <Form.Item
                            name="clientSide"
                            label="Client Side"
                            initialValue={false}
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            name="bonusActivity"
                            label="Bonus Activity"
                            initialValue={false}
                            valuePropName="checked"
                          >
                            <Switch disabled={isEditMode} />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            name="flexiActivity"
                            label="Flexi Award"
                            initialValue={false}
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            name="globalValidatorSkip"
                            label="Is Whitelisted?"
                            initialValue={false}
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            name="timeBound"
                            label={
                              <Tooltip
                                placement="topLeft"
                                title="Reward against any activity can be set for a fixed time period or unlimited time. Choose 'Yes' for fixed time period else 'No' for unlimited time."
                              >
                                Time Bound ?
                              </Tooltip>
                            }
                            initialValue={false}
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.timeBound !== currentValues.timeBound
                          }
                        >
                          {({ getFieldValue }) => {
                            return getFieldValue("timeBound") === true ? (
                              <>
                                <Col span={8}>
                                  <Form.Item
                                    name="startDate"
                                    label="Start Date "
                                  >
                                    <DatePicker />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item name="endDate" label="End Date ">
                                    <DatePicker />
                                  </Form.Item>
                                </Col>
                              </>
                            ) : null;
                          }}
                        </Form.Item>
                      </Row>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={state.submitting}
                  style={{ marginTop: "25px" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <Modal
              title="Activity"
              visible={state?.visible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              {state?.message}
            </Modal>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default CreateActivityOld;
