import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { updateUserRiskProfile } from "../../../redux/slices/authSlice";

const InterestRateUpdate = ({ userCategory, customerId }) => {
  const dispatch = useDispatch();

  const [userType, setUserType] = useState("");

  const handleUpdateInterest = () => {
    if (!userType) {
      Swal.fire({
        title: "Oops",
        text: "Please select valid value",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
      return;
    }
    dispatch(updateUserRiskProfile({ customerId, user_type: userType }));
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <div className="mainContainer">
        <div className="riskInputContainer">
          <label className="riskLabel">Update Higher Interest rate</label>
          <select
            value={userType || userCategory}
            onChange={(e) => setUserType(e.target.value)}
            className="form-select"
          >
            <option value="">SELECT</option>
            <option value="NORMAL">NORMAL</option>
            <option value="HIGH_INTEREST">HIGH_INTEREST</option>
          </select>
        </div>
        <div className="riskInputContainer">
          <label className="riskLabel">Action</label>
          <Button
            variant="contained"
            size="small"
            color="warning"
            onClick={() => handleUpdateInterest(userType)}
          >
            Update
          </Button>
        </div>
      </div>
    </Box>
  );
};
export default InterestRateUpdate;
