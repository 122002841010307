import React, { useEffect } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { TextField, Button, Tabs, Tab } from "@mui/material";
import TabPanel from "../../components/TabPanel";
import localforage from "localforage";
import {
  accountSync,
  changeUserEmail,
  deleteUserService,
  enableEmailOtp,
  getEmailEventsHistory,
  getOffersTrackingHistory,
  getUserAccountDetails,
  getUserProfile,
  getWallet,
  getWalletHistory,
  restoreUserService,
  updateUserPhone,
  updateUserStatus,
  userReferralService,
} from "../../services/user";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { inputFormat, startLoader, stopLoader } from "../../libs/utils";
import Swal from "sweetalert2";
import ListTransaction from "../transactions/transactions/transactionslist";
import {
  REFERRAL_WALLET_STATUS_MAPPING,
  allowedRegex,
} from "../../constants/index";
import PlaidTransactionList from "../transactions/user-plaid-history/PlaidTransactionList";
import styles from "../../assets/css/userProfile.module.scss";
import { error } from "jquery";

const BTN_STYLE = { height: "48px", marginLeft: "8px" };

function Users() {
  const location = useLocation();
  const navigation = useNavigate();
  const [value, setValue] = React.useState(0);
  const [identifier, setIdentifier] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [wallet, setWallet] = useState({});
  const [walletHistory, setWalletHistory] = useState([]);
  const [eventHistory, setEventHistory] = useState([]);
  const [emailEventHistory, setEmailEventHistory] = useState([]);
  const [bankTransactionHistory, setBankTransactionHistory] = useState([]);
  const [offersTrackingHistory, setOffersTrackingHistory] = useState([]);
  const [userAccountDetails, setUserAccountDetails] = useState([]);
  const [referralDetails, setReferralDetails] = useState([]);
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("identifier")?.replace(/\ /g, "+");
  const [newEmail, setNewEmail] = useState("");
  const [isofferTxnhistory, setOfferTxnHistory] = useState(false);
  const [newPhone, setNewPhone] = useState("");
  const [agenToken, setAgentToken] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    deleted,
    id: userId,
    is_active,
    first_name,
    last_name,
    email: userEmail,
    last_login,
    refer_code,
    referrer_email,
    referral_wallet_status,
    entry_time,
    sign_up_source,
    activebanks,
    club_member,
    remark,
    refer_amount,
    concierge_validity,
    current_subscription_plan,
    current_subscription_purchase_date,
    mobile_number,
    id,
    user_migrated,
    otp_attempts,
    customer_id,
    email_otp_enabled,
  } = userProfile;

  const [emailOtpEnabled, setEmailOtpEnabled] = useState(email_otp_enabled);

  const searchUser = () => {
    startLoader();
    if (!identifier && !emailParam) {
      stopLoader();
      Swal.fire({
        title: "Oops",
        text: "Please fill email",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    stopLoader();
    setValue(0);
    navigation(`/app/user?identifier=${identifier ? identifier : emailParam}`);
    return;
  };

  const walletHistoryColumns = [
    { id: "Amount", label: "Amount", minWidth: 100 },
    { id: "CashbackStatus", label: "Cashback Status.", minWidth: 100 },
    { id: "StoreName", label: "Store Name", minWidth: 100 },
    { id: "EntryTime", label: "Entry Time", minWidth: 100 },
    { id: "Pending", label: "Pending", minWidth: 100 },
    { id: "Remark", label: "Remark", minWidth: 100 },
    { id: "MaturityDate", label: "Maturity Date", minWidth: 100 },
    { id: "TxnDate", label: "Transaction Date", minWidth: 100 },
    { id: "SpentAmount", label: "Spent Amount", minWidth: 100 },
    { id: "IssuedBy", label: "Issued By", minWidth: 100 },
  ];

  // const eventHistoryColumns = [
  //   { id: "UniqueId", label: "Unique Id", minWidth: 100 },
  //   { id: "Name", label: "Name", minWidth: 100 },
  //   { id: "Type", label: "Type", minWidth: 100 },
  //   { id: "Source", label: "Source", minWidth: 100 },
  //   { id: "RequestBody", label: "Request Body", minWidth: 100 },
  //   { id: "ResponseBody", label: "	Response Body", minWidth: 100 },
  //   { id: "ResponseCode", label: "Response Code", minWidth: 100 },
  //   { id: "ResponseStatus", label: "Response Status", minWidth: 100 },
  //   { id: "Summary", label: "Summary", minWidth: 100 },
  //   { id: "IpAddress", label: "Ip Address", minWidth: 100 },
  //   { id: "Version", label: "Version", minWidth: 100 },
  //   { id: "UserId", label: "User Id", minWidth: 100 },
  //   { id: "ExceptionMessage", label: "Exception Message", minWidth: 100 },
  // ];

  const emailEventHistoryColumns = [
    { id: "UserId", label: "User Id", minWidth: 100 },
    { id: "Type", label: "Type", minWidth: 100 },
    { id: "Success", label: "Success", minWidth: 100 },
    { id: "ExceptionMessage", label: "ExceptionMessage", minWidth: 100 },
  ];

  // const bankTransactionHistoryColumns = [
  //   { id: "UserId", label: "User Id", minWidth: 100 },
  //   { id: "UniqueIdentifier", label: "Unique Identifier", minWidth: 100 },
  //   { id: "AddedSuccessful", label: "Added Successful", minWidth: 100 },
  //   { id: "FirstBank", label: "First Bank", minWidth: 100 },
  //   { id: "CashbackGiven", label: "Cashback Given", minWidth: 100 },
  //   { id: "Failure Reason", label: "Failure Reason", minWidth: 100 },
  //   {
  //     id: "FailureDetailReason",
  //     label: "Failure Detail Reason",
  //     minWidth: 100,
  //   },
  //   { id: "CreatedAt", label: "Created At", minWidth: 100 },
  //   { id: "UpdatedAt", label: "Updated At", minWidth: 100 },
  // ];

  const offersTrackingHistoryColumns = [
    { id: "OfferName", label: "Offer Name", minWidth: 100 },
    { id: "UserId", label: "User Id", minWidth: 100, align: "center" },
    { id: "OfferId", label: "Offer Id", minWidth: 100, align: "center" },
    {
      id: "UserOnboardingDate",
      label: "User Onboarding Date",
      minWidth: 100,
      align: "center",
    },
    {
      id: "OfferOpenDate",
      label: "Offer Open Date",
      minWidth: 100,
      align: "center",
    },
    {
      id: "LastOfferOpenDate",
      label: "Last Offer Open Date",
      minWidth: 100,
      align: "center",
    },
    {
      id: "CashbackTimes",
      label: "Number of Cashback Claims Left",
      minWidth: 100,
      align: "center",
    },
  ];

  const userAccountDetailsColumns = [
    { id: "AccountId", label: "Account Id", minWidth: 100 },
    { id: "BankName", label: "Bank Name", minWidth: 100 },
    { id: "Mask", label: "Mask", minWidth: 100 },
    { id: "AccountType", label: "Account Type", minWidth: 100 },
    { id: "AccountSubType", label: "Account SubType", minWidth: 100 },
    { id: "FailureReason", label: "Failure Reason", minWidth: 100 },
    { id: "BankLinkDate", label: " Bank Link Date", minWidth: 100 },
    { id: "ExpiryDate", label: " Next Sync Date", minWidth: 100 },
    { id: "BankLinkState", label: "Bank link state", minWidth: 100 },
  ];

  const userReferralColumns = [
    { id: "email", label: "Email", minWidth: 160 },
    { id: "referee_amount", label: "Referee Amount", minWidth: 100 },
    { id: "refer_amount", label: "Referrer Amount", minWidth: 100 },
    { id: "referralPending", label: "Referral Pending", minWidth: 100 },
    { id: "source", label: "Source", minWidth: 100 },
    { id: "signupDate", label: "Signup Date", minWidth: 100 },
    { id: "bankLinkDate", label: " Bank Link Date", minWidth: 100 },
    {
      id: "firstTransactionDate",
      label: "First Transaction Date",
      minWidth: 100,
    },
  ];

  const walletStatus = new Map([
    ["n", "New"],
    ["re", "Referral Reward"],
    ["c", "Card Added"],
    ["rc", "Referral & Card Added"],
    ["rd", "Referree Reward"],
    ["r", "Reward Displayed"],
    [null, ""],
  ]);

  const userAccountSync = (userId) => {
    accountSync(userId)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  };

  const handleInput = (e) => {
    const value = e.target.value;
    if (value.match(/^\d{10}$/g) && !value.indexOf("+1") > -1) {
      setIdentifier(`+1${value}`);
      return;
    }
    setIdentifier(value);
  };
  const changeEmail = () => {
    if (newEmail) {
      changeUserEmail(userEmail, newEmail)
        .then((response) => {
          setNewEmail("");

          if (response.status === `user with email ${newEmail} already exist`) {
            Swal.fire({
              title: "Oops",
              text: response.status,
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
          } else {
            Swal.fire({
              title: "Success",
              text: "Email has been changed successfully",
              icon: "success",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                navigation(`/app/user?email=${newEmail}`);
              }
            });
          }
        })
        .catch((error) => {});
    } else {
      Swal.fire({
        title: "Oops",
        text: "Please fill email",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
    }
  };

  const updateStatus = (email, status) => {
    updateUserStatus(email, status)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text:
            response.is_active === true
              ? "User unblocked successfully"
              : "User blocked successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });

        getUserProfile(emailParam)
          .then((response) => {
            setUserProfile(response);
          })
          .catch((error) => {});
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  };

  const deleteUser = (email) => {
    deleteUserService(email)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: response.status,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
        getUserProfile(emailParam)
          .then((response) => {
            setUserProfile(response);
          })
          .catch((error) => {});
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  };

  const restoreUser = (email) => {
    restoreUserService(email)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: response.status,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
        getUserProfile(emailParam)
          .then((response) => {
            setUserProfile(response);
          })
          .catch((error) => {});
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops",
          text: "Something went wrong",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  };

  useEffect(() => {
    if (emailParam) {
      startLoader();

      //User Profile
      getUserProfile(emailParam)
        .then((response) => {
          stopLoader();
          localforage.setItem("@userinfo", response, (error, data) => {
            if (error) {
            }
            stopLoader();
            setUserProfile(response);
            localforage.getItem("@userinfo", (data) => {
              if (response) {
                const { email: userMail } = response;
                getWallet(userMail)
                  .then((response) => {
                    setWallet(response);
                  })
                  .catch((error) => {});

                getWalletHistory(userMail)
                  .then((response) => {
                    if (response?.length > 0 && typeof response === "object") {
                      setWalletHistory(
                        response.map((data) => {
                          return {
                            Amount: data.amount,
                            CashbackStatus: data.transaction_type,
                            StoreName: data.storename,
                            EntryTime: data.entry_time,
                            Pending: String(data.is_pending),
                            Remark: data.remark,
                            MaturityDate: data.maturity_date
                              ? moment(data.maturity_date).format("MMM D, YYYY")
                              : "",
                            TxnDate: data.action_date
                              ? moment(data.action_date).format("MMM D, YYYY")
                              : "",
                            SpentAmount: data.spent_amount,
                            IssuedBy: data.created_by
                              ? data.created_by
                              : "SYSTEM",
                          };
                        })
                      );
                    } else {
                      setWalletHistory([]);
                    }
                  })
                  .catch((error) => {});
                //Event History
                // getEventHistory(emailParam)
                //   .then((response) => {
                //     if (response?.length > 0 && typeof response === "object") {
                //       setEventHistory(
                //         response.map((data) => {
                //           return {
                //             UniqueId: data.eventUniqueId,
                //             Name: data.eventName,
                //             Type: data.eventType,
                //             Source: data.eventSource,
                //             RequestBody: data.eventRequestBody,
                //             ResponseBody: data.eventResponseBody,
                //             ResponseCode: data.eventResponseCode,
                //             ResponseStatus: data.eventResponseStatus,
                //             Summary: data.eventSummary,
                //             IpAddress: data.eventIpAddress,
                //             Version: data.eventVersion,
                //             UserId: data.eventUserId,
                //             ExceptionMessage: data.exceptionMessage,
                //           };
                //         })
                //       );
                //     } else {
                //       setEventHistory([]);
                //     }
                //   })
                //   .catch((error) => {
                //
                //   });

                //Email Event History
                getEmailEventsHistory(userMail)
                  .then((response) => {
                    if (response?.length > 0 && typeof response === "object") {
                      setEmailEventHistory(
                        response.map((data) => {
                          return {
                            UserId: data.userId,
                            Type: data.type,
                            Success: String(data.success),
                            ExceptionMessage: data.exceptionMessage,
                          };
                        })
                      );
                    } else {
                      setEmailEventHistory([]);
                    }
                  })
                  .catch((error) => {});

                //Bank add transaction history
                // getBankTransactionHistory(emailParam)
                //   .then((response) => {
                //     if (response?.length > 0 && typeof response === "object") {
                //       setBankTransactionHistory(
                //         response.map((data) => {
                //           return {
                //             UserId: data.userId,
                //             UniqueIdentifier: data.uniqueIdentifier.map((item, i) => {
                //               return <div key={i}>{item}</div>;
                //             }),
                //             AddedSuccessful: String(data.addedSuccessful),
                //             FirstBank: String(data.firstBank),
                //             CashbackGiven: String(data.cashbackGiven),
                //             FailureReason: data.failureReason,
                //             FailureDetailReason: data.failureDetailReason,
                //             CreatedAt: moment(data.createdAt).format("MMM D, YYYY"),
                //             UpdatedAt: moment(data.updateddAt).format("MMM D, YYYY"),
                //           };
                //         })
                //       );
                //     } else {
                //       setBankTransactionHistory([]);
                //     }
                //   })
                //   .catch((error) => {
                //
                //   });
                userReferralService(userMail)
                  .then((response) => {
                    if (response.length) {
                      setReferralDetails(
                        response.map((referral) => {
                          const {
                            email,
                            referee_amount,
                            refer_amount,
                            source,
                            signup_date,
                            bank_link_date,
                            first_transaction_date,
                          } = referral;
                          return {
                            email,
                            referee_amount,
                            refer_amount,
                            referralPending: referral.referral_pending
                              ? "Pending"
                              : "Confirmed",
                            source,
                            signupDate: signup_date
                              ? moment
                                  .utc(moment(signup_date))
                                  .format("MMM D, YYYY")
                              : "",
                            bankLinkDate: bank_link_date
                              ? moment
                                  .utc(moment(bank_link_date))
                                  .format("MMM D, YYYY")
                              : "",
                            firstTransactionDate: first_transaction_date
                              ? moment
                                  .utc(moment(first_transaction_date))
                                  .format("MMM D, YYYY")
                              : "",
                          };
                        })
                      );
                    } else {
                      setReferralDetails([]);
                    }
                  })
                  .catch((error) => {});

                getUserAccountDetails(userMail)
                  .then((response) => {
                    if (response?.length > 0 && typeof response === "object") {
                      setUserAccountDetails(
                        response.map((data) => {
                          return {
                            AccountId: data.accountId,
                            BankName: data.bankName,
                            Mask: data.mask,
                            AccountType: data.accountType,
                            AccountSubType: data.accountSubtype,
                            Relink_Require: String(data.relinked),
                            Active_Linked: String(data.active),
                            FailureReason: data.failure_reason,
                            BankLinkDate: data.bankLinkedDate
                              ? moment(data.bankLinkedDate).format(
                                  "MMM D, YYYY"
                                )
                              : "",
                            ExpiryDate: data.nextSyncDate
                              ? moment
                                  .utc(moment(data.nextSyncDate))
                                  .format("MMM D, YYYY")
                              : "",
                            BankLinkState: String(data.bankLinkState),
                          };
                        })
                      );
                    } else {
                      setUserAccountDetails([]);
                    }
                  })

                  .catch((error) => {});
              }
            });
          });
        })
        .catch((error) => {
          stopLoader();
          if (error.status === 404) {
            setUserProfile({});
            Swal.fire({
              title: "Oops",
              text: "User not found",
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
          } else {
            Swal.fire({
              title: "Oops",
              text: "Something went wrong",
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {});
          }
        });
    }
    setIdentifier(emailParam);
  }, [emailParam, location]);

  useEffect(() => {
    if (userId) {
      //Offer tracking history
      getOffersTrackingHistory(userId)
        .then((response) => {
          setOfferTxnHistory(true);
          setOffersTrackingHistory(
            response.map((data) => {
              return {
                OfferName: data.offerName,
                UserId: data.userId,
                OfferId: String(data.offerId),
                UserOnboardingDate: moment(data.userOnboardingDate).format(
                  "MMM D, YYYY"
                ),
                OfferOpenDate: moment(data.offerOpenDate).format("MMM D, YYYY"),
                LastOfferOpenDate: moment(data.lastOfferOpenDate).format(
                  "MMM D, YYYY"
                ),
                CashbackTimes: `${data.cashbackTimes} / ${data.maxCashbackTimes}`,
              };
            })
          );
        })
        .catch((error) => {});
    }
  }, [userId]);

  const codeRegex = /^[0-9]{1,6}$/;
  const handleTokenChange = (e) => {
    const value = e.target.value;
    if (value) {
      if (!codeRegex.test(value)) {
        e.preventDefault();
        return;
      }
    }
    setAgentToken(value);
  };
  const handleUpdate = (newPhone, agentToken) => {
    const input = ("+1" + newPhone).replace(/[- )(]/g, "");
    const mobileNumber = input.replace("+1", "");
    if (mobileNumber.length !== 10) {
      Swal.fire({
        title: "Oops",
        text: "Please enter valid phone number",
        icon: "warning",
        confirmButtonText: "Ok",
      }).then((result) => {});
      return;
    }
    updateUserPhone({
      new_mobile_number: input,
      token: agentToken,
      customer_id: customer_id,
    })
      .then((response) => {
        setNewPhone("");
        setAgentToken("");
        Swal.fire({
          title: "Success",
          text: response.status,
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
        const errorMessage = error.data.error.message;
        Swal.fire({
          title: "Oops",
          text: errorMessage,
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  };
  const handleKey = (e) => {
    if (!e.key.match(allowedRegex)) {
      e.preventDefault();
    }
  };
  const handleMobileChange = (e) => {
    const tempVal = inputFormat(e);
    const value = e.target.value;
    if (value) {
      if (tempVal) {
        setNewPhone(tempVal);
        setPhoneError("");
        return;
      }
    }
    setNewPhone("");
    setPhoneError("");
  };

  const handleEmailOtp = () => {
    if (emailOtpEnabled !== "") {
      enableEmailOtp(mobile_number, emailOtpEnabled)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: response.status,
            icon: "success",
            confirmButtonText: "Ok",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops",
            text: `${error?.data?.error?.message}`,
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((result) => {});
        });
    }
  };

  const gotoRemittance = () => {
    navigation(`/app/search-users?email=${userEmail}`);
  };

  return (
    <>
      <Box sx={{ px: 6 }}>
        <Typography
          component="h4"
          variant="h4"
          color="inherit"
          noWrap
          sx={{ mb: 2, fontSize: "22px", fontWeight: "700" }}
        >
          Search User
        </Typography>

        <div>
          <div className="searchUserHeader">
            <div>
              <TextField
                style={{ width: "280px" }}
                label="Email/Phone"
                variant="standard"
                value={identifier}
                onChange={(e) => handleInput(e)}
              />
              <Button
                onClick={() => searchUser()}
                style={BTN_STYLE}
                variant="contained"
              >
                Submit
              </Button>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {is_active === true && (
                <>
                  <TextField
                    style={{ width: "280px" }}
                    label="Enter New Email"
                    variant="standard"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />

                  <Button
                    onClick={() => changeEmail()}
                    style={BTN_STYLE}
                    variant="contained"
                  >
                    Change Email
                  </Button>

                  <Button
                    onClick={() => userAccountSync(userId)}
                    style={BTN_STYLE}
                    variant="contained"
                  >
                    Account Sync
                  </Button>
                  <Button
                    onClick={() => updateStatus(userEmail, false)}
                    style={BTN_STYLE}
                    variant="contained"
                    color="error"
                  >
                    Block
                  </Button>
                </>
              )}
              {is_active === false && (
                <Button
                  onClick={() => updateStatus(userEmail, true)}
                  style={BTN_STYLE}
                  variant="contained"
                  color="success"
                >
                  Unblock
                </Button>
              )}
              {deleted === null && (
                <Button
                  onClick={() => deleteUser(userEmail)}
                  style={BTN_STYLE}
                  variant="contained"
                  color="warning"
                >
                  Delete
                </Button>
              )}

              {userId && deleted !== null && (
                <Button
                  onClick={() => restoreUser(userEmail)}
                  style={BTN_STYLE}
                  variant="contained"
                  color="secondary"
                >
                  Restore
                </Button>
              )}
            </div>
          </div>
          {userId && (
            <>
              <Box sx={{ mt: 3, borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{}}
                >
                  <Tab
                    className="tabStyle"
                    label="User Profile"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="tabStyle"
                    label="Update Profile"
                    {...a11yProps(1)}
                  />
                  <Tab className="tabStyle" label="Wallet" {...a11yProps(2)} />
                  <Tab
                    className="tabStyle"
                    label="Wallet History"
                    {...a11yProps(3)}
                  />
                  {/* <Tab
                    className="tabStyle"
                    label="Event History"
                    {...a11yProps(3)}
                  /> */}
                  <Tab
                    className="tabStyle"
                    label="Email Events History"
                    {...a11yProps(4)}
                  />
                  <Tab
                    className="tabStyle"
                    label="Offers Tracking History"
                    {...a11yProps(5)}
                  />
                  <Tab
                    className="tabStyle"
                    label="User Account Details"
                    {...a11yProps(6)}
                  />
                  <Tab
                    className="tabStyle"
                    label="Transaction History"
                    {...a11yProps(7)}
                  />
                  <Tab
                    className="tabStyle"
                    label="Referee Info"
                    {...a11yProps(8)}
                  />
                  <Tab
                    className="tabStyle"
                    label="User Txn History"
                    {...a11yProps(9)}
                  />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <div className="listBox" style={{ border: "solid 1px #ccc" }}>
                  <div className="listItem">
                    <div className="itemHead">Go to Remittance:</div>
                    <div className="hightlight" onClick={gotoRemittance}>
                      Go to Remittance Details
                    </div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">First Name:</div>
                    <div>{first_name}</div>
                  </div>

                  <div className="listItem">
                    <div className="itemHead">Last Name:</div>
                    <div>{last_name}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Email:</div>
                    <div>{userEmail}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Last Login:</div>
                    <div>{last_login}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Refer Code:</div>
                    <div>{refer_code}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Referrer Email:</div>
                    <div>{referrer_email}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Phone:</div>
                    <div>{mobile_number}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">User Id:</div>
                    <div>{id}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Otp Attempts:</div>
                    <div>{otp_attempts}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Migrated:</div>
                    <div>{user_migrated.toString()}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Referrer Wallet Status:</div>
                    <div>
                      {REFERRAL_WALLET_STATUS_MAPPING[referral_wallet_status]}
                    </div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Entry Time:</div>
                    <div>{entry_time}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Signup Source:</div>
                    <div>{sign_up_source}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Active Banks:</div>
                    <div>{activebanks}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Club Member:</div>
                    <div>{club_member}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Remark:</div>
                    <div>{remark}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Refer Amount:</div>
                    <div>{refer_amount}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Concierge validity:</div>
                    <div>{concierge_validity}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Active:</div>
                    <div>{String(is_active)}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Account Sync Status:</div>
                    <div></div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Current Subscription Plan:</div>
                    <div>{current_subscription_plan}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Last Purchase Date:</div>
                    <div>{current_subscription_purchase_date}</div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <div className="mainContainer">
                    <div className="riskInputContainer">
                      <label className="riskLabel">New Phone Number</label>
                      <div>
                        <span className={styles.phoneInput}>+1</span>
                        <input
                          className={styles.inputBox}
                          id="phone"
                          value={newPhone}
                          placeholder="New Phone Number"
                          onChange={handleMobileChange}
                          onKeyPress={handleKey}
                          type="tel"
                          maxLength={14}
                        />
                      </div>
                    </div>

                    {/* <span className={`formControlMessage`}>{phoneError}</span> */}

                    <div className="riskInputContainer">
                      <label className="riskLabel">Agent Token</label>
                      <input
                        value={agenToken}
                        placeholder="Please Enter Code"
                        margin="normal"
                        autoFocus
                        onChange={handleTokenChange}
                        className={styles.inputBox}
                      />
                    </div>
                    <div className="riskInputContainer">
                      <label className="riskLabel">Action</label>
                      <Button
                        variant="contained"
                        size="small"
                        color="warning"
                        onClick={() => handleUpdate(newPhone, agenToken)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                  <div className="mainContainer">
                    <div className="riskInputContainer">
                      <label className="riskLabel">Enable Email Otp</label>
                      <select
                        value={emailOtpEnabled || email_otp_enabled}
                        onChange={(e) => setEmailOtpEnabled(e.target.value)}
                        className="form-select"
                        style={{ width: "247px" }}
                      >
                        <option value="" selected>
                          SELECT
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>

                    <div className="riskInputContainer">
                      <div className="riskLabel"></div>
                      <Button
                        variant="contained"
                        size="small"
                        color="warning"
                        onClick={() => handleEmailOtp(emailOtpEnabled)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="listBox" style={{ border: "solid 1px #ccc" }}>
                  <div className="listItem">
                    <div className="itemHead">Status:</div>
                    <div>{walletStatus.get(wallet.status || null)}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Cash Earned:</div>
                    <div>{wallet.cashearned}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Cash Burned:</div>
                    <div>{wallet.cashburned}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Amount:</div>
                    <div>{wallet.amount}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Pending Amount:</div>
                    <div>{wallet.pendingamount}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Currency:</div>
                    <div>{wallet.currency}</div>
                  </div>
                  <div className="listItem">
                    <div className="itemHead">Notification:</div>
                    <div>{String(wallet.notification)}</div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <CustomTable
                  data={walletHistory}
                  columns={walletHistoryColumns}
                  defaultRowPerPage={500}
                />
              </TabPanel>

              <TabPanel value={value} index={4}>
                <CustomTable
                  data={emailEventHistory}
                  columns={emailEventHistoryColumns}
                />
              </TabPanel>

              <TabPanel value={value} index={5}>
                <CustomTable
                  data={offersTrackingHistory}
                  columns={offersTrackingHistoryColumns}
                  tabType={isofferTxnhistory}
                />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <CustomTable
                  data={userAccountDetails}
                  columns={userAccountDetailsColumns}
                />
              </TabPanel>
              <TabPanel value={value} index={7}>
                <ListTransaction email={emailParam} />
              </TabPanel>
              <TabPanel value={value} index={8}>
                <CustomTable
                  data={referralDetails}
                  columns={userReferralColumns}
                />
              </TabPanel>
              <TabPanel value={value} index={9}>
                <PlaidTransactionList
                  userAccountDetails={userAccountDetails}
                  customer_id={customer_id}
                  identifier={identifier ? identifier : emailParam}
                />
              </TabPanel>
            </>
          )}
        </div>
      </Box>
    </>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default Users;
