import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import localforage from "localforage";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import { filterList } from "../../../libs/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import { getActivityList } from "../../../services/timesPoint/timesPointActivity";
import Swal from "sweetalert2";

const ActivityList = () => {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isDynamicTable, setIsDynamicTable] = useState(false);

  const columns = [
    {
      id: "category",
      label: "Category",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },
    {
      id: "spacode",
      label: "Activity Code",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },
    {
      id: "desc",
      label: "Title",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },
    {
      id: "maxcap",
      label: "Maxcap",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },

    {
      id: "points",
      label: "Points",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },
    {
      id: "max_cap_duration",
      label: "Maxcap Duration",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },
    {
      id: "credit_limit",
      label: "Credit Limit",
      minWidth: 100,
      align: "left",
      enableSort: true,
    },
    {
      id: "start_date",
      label: "Start Date",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },
    {
      id: "end_date",
      label: "End Date",
      minWidth: 100,
      sortable: true,
      align: "left",
      enableSort: true,
    },

    {
      id: "Action",
      label: "Action",
      minWidth: 100,
      align: "left",
    },
  ];

  const setDynamicTable = (parentWidth, tableWidth) => {
    setIsDynamicTable(true);
  };

  const searchData = (e) => {
    setSearchKey(e.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataGenerator = (data) => {
    return {
      category: data.category,
      maxcap: data.maxcap,
      credit_limit: data.credit_limit,
      spacode: data.spacode,
      points: data.points,
      max_cap_duration: data.max_cap_duration,
      time_bound: data.time_bound,
      start_date: data.start_date,
      end_date: data.end_date,
      desc: data.desc,
      Action: (
        <Link
          to={`/app/activity/${data.spacode}`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" size="small" color="warning">
            Edit
          </Button>
        </Link>
      ),
    };
  };

  useEffect(() => {
    setLoading(true);
    localforage.getItem("@merchants", (error, data) => {
      if (data) {
        if (data?.length > 0 && typeof data === "object") {
          setRows(data.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
      }
    });
    getActivityList()
      .then((response) => {
        setLoading(false);
        if (response && typeof response === "object") {
          setRows(response.activities?.map((data) => dataGenerator(data)));
        } else {
          setRows([]);
        }
        localforage.setItem("@merchants", response, (error, data) => {
          if (error) {
            throw error;
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Oops",
          text: error.data.error.message,
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  }, []);
  return (
    <>
      <Box
        sx={{ px: 6 }}
        className={`main-box ${isDynamicTable ? "dynamicTableStyle" : ""}`}
      >
        <div
          className={`bankAddRuleHeader mb-3 ${
            isDynamicTable ? "listHeaderPaddingRight" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              padding: "1.5rem 0",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#33343a" }} variant="h4" component="h4">
              Activity List
            </Typography>
            {loading && (
              <div
                style={{
                  marginLeft: "12px",
                  color: "#a0a0a0",
                  fontSize: "24px",
                }}
              >
                <i
                  className={`fa fa-refresh ${
                    loading === true ? "fa-spin" : ""
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{ mr: 1 }}
              onKeyUp={(e) => searchData(e)}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "#8a8a8a" }} />,
              }}
            />

            <Tooltip title="Choose Option">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVertIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                sx={{
                  "&:hover": {
                    background: "#f9da8792",
                    color: "#000",
                  },
                }}
              >
                <Link to="/app/activity/0" style={{ color: "#040721" }}>
                  Add New Activity
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>

        <CustomTable
          setDynamicTable={setDynamicTable}
          data={filterList(rows, searchKey) || []}
          columns={columns}
        />
      </Box>
    </>
  );
};

export default ActivityList;
