import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import Swal from "sweetalert2";

import RiskProfile from "./RiskProfileUpdate";
import { deleteRemittanceUser } from "../../../services/blockedUsersService";

const UserDetails = ({
  userDetails = {},
  riskScore,
  setRiskScore,
  searchParams,
  setSerachParams,
}) => {
  const navigate = useNavigate();

  const sections = [
    {
      type: "kycDetails",
      title: "KYC Details",
    },
    {
      type: "userControls",
      title: "User controls",
    },
  ];

  const {
    mobileNo,
    // userStatus,
    // userOptedInBanking,
    // debitCardActivated,
    // debitCardDelivered,
    // userOptedInRemittance,
    // userBlocked,
    // cipTag,
    email,
    firstName = "",
    middleName = "",
    lastName = "",
    customerId,
    userBlocked,
    kycStatus = "status3",
    referenceId,
  } = userDetails || {};
  console.log(userDetails);

  const KYC_STATUS_MAPPING = {
    status1: {
      text: "KYC documents are submitted and  waiting for approval from the partner",
      colorCode: "yellow",
    },
    status2: {
      text: "KYC approved ",
      colorCode: "green",
    },
    status3: {
      text: "Further information is required from the user to complete the KYC",
      colorCode: "red",
    },
    status4: {
      text: "The user hasn’t started the KYC yet",
      colorCode: "grey",
    },
    status5: {
      text: "KYC is marked as failed by the partner",
      colorCode: "red",
    },
  };

  const { text, colorCode } = KYC_STATUS_MAPPING[kycStatus] || {};

  const mapping = [
    { key: "Full Name", value: `${firstName} ${middleName} ${lastName}` },
    { key: "Email", value: email },
    { key: "Mobile No", value: mobileNo },
    // { key: "KYC Status", cls: colorCode, value: text },
    {
      key: "Layer 2 dashboard link",
      value: `https://management.layer2financial.com/customers/${customerId}/accounts`,
      isLink: true,
    },
    { key: "Delete user", type: "deleteUser" },
    // { key: "Debit Card activated", value: debitCardActivated ? "Yes" : "No" },
    // { key: "Debit Card delivered", value: debitCardDelivered ? "Yes" : "No" },
    // { key: "Synapse CIP tag", value: cipTag },
    // { key: " Remittance", value: userOptedInRemittance ? "Yes" : "No" },
    // { key: "Neobank", value: userOptedInBanking ? "Yes" : "No" },
    // { key: "Blocked", value: userBlocked ? "Yes" : "No" },
  ];

  const handleDeleteUser = () => {
    deleteRemittanceUser(referenceId)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "User has been deleted successfully!",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            searchParams?.delete("email");
            setSerachParams(searchParams);
            navigate(0);
          }
        });
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error?.message || "Something went wrong";
        Swal.fire({
          title: "Oops",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const renderKycItems = ({ key, value, isLink, cls = "", type = "" }) => {
    if (isLink) {
      return (
        <div>
          <a href={value} target="_blank">
            Go to Dashboard
          </a>
        </div>
      );
    }
    if (key === "KYC Status") {
      return (
        <div className="kycStatus">
          <div>{value}</div>
          <div className={`kycStatusText dot ${cls}`} />
        </div>
      );
    }
    if (type === "deleteUser") {
      return (
        <div>
          <Button
            variant="contained"
            size="medium"
            color="error"
            onClick={handleDeleteUser}
          >
            Delete
          </Button>
        </div>
      );
    }
    return <div>{value}</div>;
  };

  const renderKycDetails = () => {
    return mapping.map(
      ({ key, value, isLink = false, cls, type = "" }, index) => (
        <div className="listBox">
          <div className="listItem">
            <div className="itemHead">{key}</div>
            {renderKycItems({ key, value, isLink, cls, type })}
          </div>
        </div>
      )
    );
  };

  const renderLimit = () => {
    const rows = [
      {
        type: "Daily",
        limit: `$5,000 (left: $190)`,
      },
      {
        type: "Monthly",
        limit: `$5,000 (left: $190)`,
      },
      {
        type: "Yearly",
        limit: `$5,000 (left: $190)`,
      },
    ];

    return (
      <Table sx={{ maxWidth: 400, border: 0.5 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: 0.5, background: "#F1F2F4" }}></TableCell>
            <TableCell
              sx={{ border: 0.5, background: "#F1F2F4" }}
              align="center"
            >
              Remitance limits
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" sx={{ border: 0.5 }}>
                {row.type}
              </TableCell>
              <TableCell sx={{ border: 0.5 }} align="center">
                {row.limit}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderUserControls = () => {
    const blockedCls = userBlocked ? "red" : "green";
    //TODO
    const showUnblockBtn = false;
    const showLimits = false;
    return (
      <>
        <div className="listItem">
          <div className="itemHead">Remittance blocked status</div>
          <div className="kycStatus">
            <div>{userBlocked ? "BLOCKED" : "NOT_BLOCKED"}</div>
            <div className={`kycStatusText dot ${blockedCls}`} />
            {userBlocked && showUnblockBtn && (
              <div className="unblockUserBtn">
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={() => {}}
                >
                  Unblock
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="userControls">
          <div className="itemHead">Risk Profile and limits</div>
          <div className="riskScore">
            <RiskProfile
              riskScore={riskScore}
              customerId={customerId}
              setRiskScore={setRiskScore}
            />
          </div>
          {showLimits && (
            <div className="limits">
              <div className="limitsHeadingText">Limits</div>
              <div>{renderLimit()}</div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="userDetails">
      {sections.map(({ type, title }) => (
        <Accordion sx={{ margin: "20px 0px" }} key={type}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {title}
          </AccordionSummary>
          <AccordionDetails>
            {type === "kycDetails" ? renderKycDetails() : renderUserControls()}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default UserDetails;
